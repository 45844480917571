<template>
  <div
    class="level"
    style="height: 80vh;">
    <div class="level-item">
      <i
        class="mdi mdi-spin mdi-loading"
        aria-hidden="true" />
      <span>&nbsp; Preparing new contact...</span>
    </div>
  </div>
</template>
<script>
// import ContactService from './ContactService'
import StoreMixin from './storeMixin'
// import _isEmpty from 'lodash/isEmpty'

export default {
  mixins: [StoreMixin],
  async mounted() {
    document.title = 'Creating New Contact'

    let fName = ''
    let lName = ''

    if (this.$route.query.fname) {
      fName = this.$route.query.fname
    }

    if (this.$route.query.lname) {
      lName = this.$route.query.lname
    }

    this.addStoreItem([`${fName} ${lName}`])
    // const res = await ContactService.getNewContact(fName, lName)

    // // this.$store.dispatch('contacts/store', res.data)
    // if (this.newAsset && !_isEmpty(this.newAsset)) {
    //   res.data.assets.push(this.newAsset)
    // }
    // const item = {
    //   id: res.data.id,
    //   entity: res.data
    // }
    // this.saveStoreItem(item)

    // this.$router.replace({
    //   name: 'ContactDetail',
    //   params: {
    //     contactId: res.data.id
    //   },
    //   query: this.$route.query
    // })
  }
}
</script>
